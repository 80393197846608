exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-all-js": () => import("./../../../src/pages/all.js" /* webpackChunkName: "component---src-pages-all-js" */),
  "component---src-pages-geo-data-js": () => import("./../../../src/pages/geo-data.js" /* webpackChunkName: "component---src-pages-geo-data-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-new-js": () => import("./../../../src/pages/new.js" /* webpackChunkName: "component---src-pages-new-js" */),
  "component---src-pages-plugins-js": () => import("./../../../src/pages/plugins.js" /* webpackChunkName: "component---src-pages-plugins-js" */),
  "component---src-pages-premium-bundles-js": () => import("./../../../src/pages/premium-bundles.js" /* webpackChunkName: "component---src-pages-premium-bundles-js" */),
  "component---src-pages-premium-plugins-js": () => import("./../../../src/pages/premium-plugins.js" /* webpackChunkName: "component---src-pages-premium-plugins-js" */),
  "component---src-pages-themes-js": () => import("./../../../src/pages/themes.js" /* webpackChunkName: "component---src-pages-themes-js" */),
  "component---src-pages-toplist-js": () => import("./../../../src/pages/toplist.js" /* webpackChunkName: "component---src-pages-toplist-js" */),
  "component---src-pages-updates-js": () => import("./../../../src/pages/updates.js" /* webpackChunkName: "component---src-pages-updates-js" */)
}

